@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.call_emergency {
  width: auto;
  border-radius: 2rem;
  padding: 1rem 2rem;
  background: #ff1f27;
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
}

a.call_emergency .icons {
  color: #fff;
  background: #d8d2d259;
  padding: 6px 17px;
  font-size: 50px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.contact_page {
  padding: 80px 0px 100px 0px;
}

.contact_us p {
  font-family: poppins !important;
  font-size: 18px;
}

.about_bg {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
 
}


@media screen and (min-width: 0px) and (max-width: 530px) {
  .contact_us p {
    font-size: 15px;
    text-align: justify;
    line-height: 25px;
  }
  .contact_us h1 {
    font-size: 25px;
  }
  .contact_us h2 {
    font-size: 20px;
    margin-top: 25px;
  }

  .contact_page {
    padding: 50px 0px 100px 0px;
  }
  .contact h3 {
    font-size: 17px;
  }
  .contact p {
    font-size: 13px;
    text-align: center;
  }
}

.contact_us h2 {
  margin-bottom: 0px !important;
  color: #ff1f27 !important;
}

.contact_us h1 {
  margin-bottom: 20px !important;
}

.About_page {
  padding: 70px 10px;
}

.About_page p {
  padding-top: 15px;
  font-size: 17px;
  text-align: justify;
  color: gray;
}

.about_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.733), rgba(0, 0, 0, 0.5)),
    /* url("https://images.pond5.com/ambulance-emergency-lights-flashing-night-footage-074063678_iconl.jpeg"); */
      url("https://img.freepik.com/free-photo/young-paramedics-moving-ambulance-stretcher-from-car-hurry-paramedics-uniform-taking-stretcher-out-ambulance-car_657921-1483.jpg?t=st=1715842672~exp=1715846272~hmac=a823dbda98b5f228d88990e436e2561a3a49e5295a9391d979aaa49614874819&w=740");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
}



.card-main {
  padding: 50px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.card-0 {
  color: #fff;
  background-color: black;
  position: relative;
  margin-left: 70px;
  border-radius: 10px;
  min-height: 312px;
}

.carousel-indicators li {
  cursor: pointer;
  border-radius: 50% !important;
  width: 10px;
  height: 10px;
}

.profile {
  color: white;
  background-color: red;
  position: absolute;
  left: -70px;
  top: 17%;
  border-radius: 8px;
  border-top-left-radius: 0px; 
  border-bottom-right-radius: 0px; 
}

.profile-pic {
  width: 120px;
  height: 120px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}
.rating_stars{
  color: yellow;
  
}
.ratings{
  display:inline-block;
  margin-top:20px;
}

.open-quotes {
  margin-left: 130px;
  margin-top: 100px;
  
}

.content {
  margin-left: 150px;
  margin-right: 80px;
}

.close-quotes {
  margin-bottom: 100px;
  margin-right: 60px
}
.rating_names{
  display: none;
}

@media screen and (min-width: 459px) and (max-width: 800px) {
  .card-main {
      padding: 50px 10px;
  }

  .card-0 {
      min-height: fit-content;
  }

  .profile {
      top: 24%;
  }

  .profile-pic {
      width: 90px;
      height: 90px;
  }

  .open-quotes {
    margin-left: 78px;
    margin-top: 50px;
  }

  .content {
    margin-left: 105px;
        margin-top: 6px;
        font-size: 12px;
        margin-right: 32px;
  }

  .close-quotes {
      margin-right: 30px;
      margin-bottom: 80px;
  }
  .rating_names{
    display: none;
  }
  .ratings{
    padding-left: 32px;
        font-size: 13px;
  }
}

@media screen and (max-width: 458px) {
  .profile {
      display: none;
  }
  .card-0{
    height: fit-content;
    margin-left: 0px;
    padding: 0px;
  }
  .content{
    margin-left: 4px;
    margin-right: 2px;
    font-size:14px;
    margin-top:10px;
  }
  .open-quotes {
     
      margin-left: 17px;
      margin-top: 18px;
    
  }
 .card-main{
  padding:5px;
  margin-bottom:0px;
  margin-top:0px;
 }
 .close-quotes {
  margin-bottom: 66px;
  margin-right: 25px
}
.rating_names{
  text-align: end;
        color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 47px;
        font-weight: 600;
        font-size: 18px;

}
.why_chooseUs_container h1{
  font-size:33px;
  font-weight: 600;
}
.about_bg h1{
  font-size: 33px;
}

}
/* @media screen and (min-width:601px) and (max-width:800px) {

  
} */

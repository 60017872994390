/* nav bar */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: poppins !important;
}
body {
  background-color: #f8f9fa !important;
}
.pabTopLogo {
  width: 200px;
  /* background-color: black;  */
  border-radius: 10px;
  padding: 10px;
}

@media screen and (min-width: 0px) and (max-width: 969px) {
  .pabTopLogo {
    width: 180px;
  }
}
#dropdown2 {
  color: black;
  font-size: 16px;
}
#drop {
  color: black;
  font-size: 16px;
}
.newHome {
  color: black;
}
.navbarHeading {
  color: black;
}
.homeBtn {
  color: #2c0264;
  font-weight: bold;
}
.home {
  color: black;
  padding: 8px;
  font-size: 15px;
}
.home:hover {
  background-color: black;
  color: #fff;

  border-radius: 5px;
}

#dropdown2:hover {
  color: white;
}
#drop:hover {
  color: white;
  background-color: black;
  border-radius: 5px;
}
.signUpBtn {
  background-color: #323190db;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
}
.navBarTwoContainer {
  display: flex;
  justify-content: space-between;
}

/* footer */
.footerSection {
  background-color: #282828;
  color: white;
}
.secondCol,
.thirdCol,
.fourthCol {
  font-size: 14px;
  /* word-spacing: 5px; */
  line-height: 1.9rem;
  margin-bottom: 25px;
}
.firstCol {
  margin-bottom: 12px;
}
.footerSection {
  padding: 40px 20px;
}
.footerText {
  font-size: 14px;
  line-height: 1.5rem;
  text-align: center;
}
.firstFooterContainer {
  justify-content: space-between;
}

.iconarr {
  padding-right: 15px;
}
#bLine {
  border-bottom: 1px solid white;
}

.logos {
  color: #ffffff;
  font-size: 30px;
}

#linkedIn,
#facebook,
#instagram,
#twitter {
  transition: 3s;
}

#linkedIn:hover,
#facebook:hover,
#instagram:hover,
#twitter:hover {
  transform: rotate(360deg);
}
.secondFooterContainer {
  justify-content: space-around;
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

/* caursoel  */
#carousel .carousel-item.boat {
  background-image: linear-gradient(rgba(0, 0, 0, 0.79), rgba(0, 0, 0, 0.79)),
    url("https://img.freepik.com/free-vector/ambulance-concept-flat-style_23-2147945291.jpg?size=626&ext=jpg&ga=GA1.1.1152151187.1702462035&semt=ais");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2px 10px;
}

#carousel .carousel-item.sea {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://img.freepik.com/premium-photo/ambulance-driving-city-street-with-clouded-sky-created-using-generative-ai-technology_772924-3504.jpg?size=626&ext=jpg&ga=GA1.1.1152151187.1702462035&semt=sph");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#carousel .carousel-item.river {
  background-image: linear-gradient(rgba(0, 0, 0, 0.79), rgba(0, 0, 0, 0.79)),
    url("https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?size=626&ext=jpg&ga=GA1.1.1152151187.1702462035&semt=sph");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#carousel .carousel-item.test {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://www.cashapona.com/wp-content/uploads/2023/01/slider-img04.png");
}
#carousel .carousel-item.test2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://www.cashapona.com/wp-content/uploads/2023/01/slider-img05.png");
}

#carousel .carousel-item {
  height: 400px;
  /* margin-top: 100px;  */
  width: 100%;
  min-height: 350px;
  background: no-repeat center center scroll;
  background-size: cover;
}

#carousel .carousel-inner .carousel-item {
  transition: -webkit-transform 2s ease;
  transition: transform 2s ease;
  transition: transform 2s ease, -webkit-transform 2s ease;
}

#carousel .carousel-item .caption {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 40px;
  color: white;
  animation-duration: 1s;
  animation-delay: 1s;
}

.calculatedContainer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.733), rgba(0, 0, 0, 0.5)),
  
      url("https://img.freepik.com/free-photo/side-view-paramedic-checking-victim_23-2149478560.jpg?t=st=1715873320~exp=1715876920~hmac=6fa31221079a3ee3fd1f5215181cec487a05aad08b9ed66489952146b2075bd3&w=740");
  /* background-image: url("https://www.red.health/build/images/background-hero.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 170px 0px;

  /* margin-top: 70px; */
}
.calculatedContainer h1{
  
  font-size: 50px;
  
  padding-left: 12px;
}
.col_md_6_text {
  animation-duration: 1s;
  animation-delay: 1s;
  color: white;
  
}

.col_md_6_form {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 7px 29px 0 #6f646400 !important;
  padding: 30px;
}

.col_md_6_text p {
  color: white;
  
}
.col_md_6_text h2 {
  color: white;
}
.col_md_6_text a {
  color: #000;
}
#carousel .caption h2 {
  animation-duration: 1s;
  animation-delay: 1s;
}

#carousel .caption p {
  animation-duration: 1s;
  animation-delay: 1.2s;
}

#carousel .caption a {
  animation-duration: 1s;
  animation-delay: 1.4s;
}

/* Button */
.delicious-btn {
  display: inline-block;

  color: #ffffff;
  border: none;
  /* border-left: 3px solid #1c8314; */
  border-radius: 0;
  padding: 10px 25px;
  font-size: 16px;

  font-weight: 600;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  text-transform: capitalize;
}

.delicious-btn.active,
.delicious-btn:hover,
.delicious-btn:focus {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #1c8314;
  border-color: #40ba37;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeInDown {
  animation-name: fadeInDown;
}

.fadeInRight {
  animation-name: fadeInRight;
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px); /* Start position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End position */
  }
}

@media (max-width: 768px) {
  .carouselHeading {
    font-size: 22px;
  }
  .carouselText {
    font-size: 14px;
  }
  .delicious-btn {
    padding: 5px 15px;
    text-align: center;
  }
  /* .technologiesHeading {
    font-size: 22px;
  } */
  .techonologiesText {
    font-size: 13px !important;
    text-align: justify !important;
  }
  .footerText {
    font-size: small;
  }
}

/* our technologies */

html {
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1 {
  margin: 0;
  padding: 0;
}
.section-header {
  display: block;
  margin-top: 20px;
  position: relative;
  text-align: center;
}
.section-header h1 {
  color: #212121;
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  padding: 0 0 24px;
  position: relative;
  z-index: 2;
}
.section-header h1:after {
  position: absolute;
  display: block;
  width: auto;
  height: 3px;
  bottom: 0;
  left: 20%;
  right: 20%;
  border-radius: 1px;
  content: "";
  z-index: 1;
  background: #e6e9ec;
}
section {
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}
a,
a:link,
a:visited {
  color: #fff;
  text-decoration: none;
}
.micro-slider {
  height: 480px;
  /* margin: 10px 0; */
  position: relative;
  width: 100%;
}
.micro-slider.fullwidth {
  height: 480px;
  /* margin: 64px 0; */
}
.micro-slider.fullwidth .slider-item {
  height: 480px;
  line-height: 480px;
  width: 100%;
}
.slider-wrapper {
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 0 50%;
}
.slider-item {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
  color: #fff;
  display: none;
  font-size: 30px;
  height: 320px;
  line-height: 320px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 320px;
  cursor: pointer;
}
/* 
.slider-item:not(.active) {cursor: pointer;} */

.indicators {
  bottom: 0px;
  left: 50%;
  list-style-type: none;
  margin: 0 0 0 -77px;
  padding: 0;
  position: absolute;
}
.indicators li {
  color: #fff;
  float: left;
  height: 16px;
  margin-right: 8px;
  text-align: center;
  width: 16px;
}
.indicators li:last-child {
  margin: 0;
}
.indicators a {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e6e9ec;
  color: #fff;
  display: inline-block;
  height: 16px;
  width: 16px;
}
.indicators .active a {
  background: #e6e9ec;
}
.s1 {
  background: #3f51b5;
}
.s2 {
  background: #448aff;
}
.s3 {
  background: #03a9f4;
}
.s4 {
  background: #00bcd4;
}
.s5 {
  background: #009688;
}
.s6 {
  background: #4caf50;
}

.technologiesHeading {
  /* color: #ff5a5f; */
  margin-top: 20px;
  font-weight: 500;
}
.heading {
  font-size: 17px;
  text-align: start;
}

.techonologiesText {
  /* font-family: "Nunito Sans", sans-serif; */
  /* letter-spacing: 0.5px; */
  word-wrap: break-word;
  font-weight: 400;
  font-size: medium;
  text-align: center;
}

/* what we are ? */

.whatImage {
  height: 700px;
}

.thirdMainDiv {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("https://www.cashapona.com/wp-content/uploads/2020/06/dgt-trans-img.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px; */
}
.custom-list {
  list-style: none; /* Remove default bullets */
  padding-left: 0px;
  font-size: 14px;
  text-align: start !important;
}
.custom-list li::before {
  content: "\2022"; /* Unicode bullet character (•) */
  color: #ff3131; /* Change the bullet color */
  display: inline-block;
  line-height: 1.8rem;
  width: 1em; /* Adjust the width of the bullet */
  margin-left: -1em; /* Move the bullet to the left */
  font-size: 14px;
}

.offerImage {
  width: 50px;
}

.offerDiv {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  padding: 20px;
  transition: background-position 0.3s ease-in-out; */

  box-shadow: rgba(31, 34, 120, 0.08) 0px 5px 30px 0px;
  margin-bottom: 2rem;
  position: relative;
  text-align: center;
  background: rgb(255, 255, 255);
  padding: 1.5rem 1rem;
  border-radius: 1rem;
}

.offerDiv:hover {
  background-position: -100% 0; /* Hover gradient colors */
}

.offerDiv {
  /* font: 22px Arial; */
  width: 100%;
  color: #000000;
  /* default color */

  /* "to left" / "to right" - affects initial color */
  /* background: linear-gradient(to left, rgba(0, 0, 0, 0.647) 50%, #ff3131 50%) */
  /* right; */
  background-size: 200%;
  transition: 0.8s ease-out;
}
.offerDiv:hover {
  background-position: left;
  .custom-list li::before {
    /* color: white; */
  }
}

@media (max-width: 425px) {
  .custom-list {
    font-size: small;
  }
  .heading {
    font-size: 13px;
  }
  .contactText {
    font-size: small;
  }
  .secondCol,
  .thirdCol,
  .fourthCol {
    font-size: small;
    /* word-spacing: 5px; */
    line-height: 1.9rem;
    margin-bottom: 25px;
  }
  .secondFooterContainer {
    font-size: small;
  }
  .logos {
    color: #ffffff;
    font-size: 20px;
  }
  .tabs_content_text {
    text-align: center;
  }
}
nav {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contactText {
  font-size: 14px;
}

@media (max-width: 992px) {
  .offerImage {
    width: 40px;
    margin-bottom: 20px;
  }
  .offerDiv {
    width: 100%;
  }
  .calculatedContainer h1 {
    font-size: 31px;
        padding-left: 12px;
        padding-bottom: 35px;
        font-weight: 700;
}
  /* .thirdMainDivheading {
    font-size: 20px;
  } */
}

/* get in touch */

.handimg {
  /* border-top-left-radius: 25px;
  border-bottom-left-radius: 25px; */
  width: 75%;
}
.conText {
  font-size: 19px;
  /* font-family: "Nunito Sans", sans-serif; */
}
.conHead {
  font-size: 18px;
  /* font-family: Montserrat, sans-serif; */
  color: #888888;
  font-weight: 600;
}
.face {
  font-size: 30px;
  color: white;
  padding: 5px;
  background-color: #3b5897;
}
.insta {
  font-size: 30px;
  color: white;
  padding: 5px;
  background-color: #ea2c59;
}
.linked {
  font-size: 30px;
  background-color: #007ab5;
  padding: 5px;
  color: white;
}
.conPhn {
  font-size: 18px;
  color: #6855d3;
  font-weight: 500;
}
@media (max-width: 768px) {
  .conText {
    font-size: 15px;
    text-align: justify;
  }
 
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}

.estimate_button {
  border-radius: 0.375rem;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.7rem;
  border: none;
  background-color: #bdb6b670;
}

.red_button {
  border-radius: 0.375rem;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.7rem;
  border: none;
  background-color: rgba(128, 128, 128, 0.644);
}

.green_button {
  border-radius: 0.375rem;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.7rem;
  border: none;
  background-color: rgba(128, 128, 128, 0.644);
}

.green_button:hover {
  background-color: #25d366;
}
.green_fixed_button {
  border-radius: 5px;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px;
  border: none;
  background-color: #25d366;
  display: flex;
  align-items: center;
  width: 185px;
  justify-content: space-evenly;
}
.green_fixed_button i {
  font-size: 22px;
}
.red_fixed_button i {
  font-size: 18px;
}
.red_fixed_button {
  border-radius: 5px;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px;
  border: none;
  background-color: #ff3131;
  display: flex;
  align-items: center;
  width: 185px;
  justify-content: space-around;
}
.red_button:hover {
  background-color: #ff3131;
}

/* tabs */
/* tabs */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: Poppins;
}

section {
  width: 100%;
  height: 100%;
  /* background-color: #dedede; */
  margin: auto;
  /* padding: 20px; */
  overflow: hidden;
}
.tabs {
  width: 100%;
  display: inline-block;
  padding-top: 30px;
}

.tab-links:after {
  display: block;
  clear: both;
  content: "";
}

.tab-links li {
  margin: 0;

  list-style: none;
}

.tab-links a {
  /* padding: 9px 30px 5px 30px; */
  /* padding: 9px 30px 5px 30px; */
  display: inline-block;
  /* margin: 10px 0px; */
  background-color: #fff;
  font-size: 16px;
  color: #000;
  transition: all linear 0.3s;
  text-align: center;
  text-decoration: none;
  /* width: 320px; */
  padding: 4px 29px;
  margin-top: 6px;
  border-radius: 4px;
}

.tab-content div {
  transition: all linear 0.3s;
  /* padding-right: 0px; */
}
.tab-links a:hover {
  background: #d74c53;
  color: #fff !important;
  text-decoration: none;
  cursor: pointer;
}

li.active a,
li.active a:hover {
  background: #ff3131;
  color: #fff !important;
}

.tab-content {
  padding-left: 15px;
}

.tab {
  display: none;
}

.tab.active {
  display: block;
}
.tabs ul {
  padding-left: 0px;
}

.tab-content h2 {
  margin: 15px 0px;
}
/* .tab-content p {
  margin: 25px 0px;
} */

.tab-content-images img {
  height: 50px;
  width: 50px;
}

.individual_iamge img {
  width: 300px;
}

.individual_iamge {
  padding: 40px 0px;
}

.tab-content {
  transition: display 0.3s linear;
}

.about-content .about-list li {
  display: block;
  color: #0a2b42;
  margin-bottom: 10px;
  font-weight: 500;
  transition: 0.7s;
  padding-left: 35px;
  position: relative;
  transition: 0.5s;
  font-size: 15px;
}

/* tabs ends */

/* .floating-icons {
  position: fixed;
  bottom: 260px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
} */

.floating-icons {
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}

.icon {
  margin-top: 10px;
  font-size: 25px;
  color: #000; /* Change the color as needed */
}

.icon {
  animation: zoomInOut 1s infinite alternate; /* Apply the zoomInOut animation */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Start with normal size */
  }
  50% {
    transform: scale(1.1); /* Zoom in */
  }
  100% {
    transform: scale(1); /* Zoom out */
  }
}

.suggestion-container {
  display: none; /* Hide the suggestion container by default */
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure suggestions appear above other elements */
  max-height: 190px; /* Limit height if needed */
  overflow-y: auto; /* Add scrollbar if suggestions overflow */
  margin-top: 10px;
}

.suggestion-container::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.suggestion-container::-webkit-scrollbar-thumb {
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ); /* Set the color of the scrollbar thumb */
  /* border-radius: 4px; Add border radius to the scrollbar thumb */
}

.suggestion-container::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}

.suggestion-item {
  padding: 0.5rem;
  cursor: pointer;
  font-size: smaller;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
  font-size: small;
}

/* .tabs_content_text h2 {
  color: #fff;
} */

@keyframes fake {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
/* 
body {
  animation: fake 1s infinite;
} */

.worko-tabs {
  margin: 20px;
  /* width: 80%; */
}

.worko-tabs .state {
  position: absolute;
  left: -10000px;
}

.worko-tabs .flex-tabs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.worko-tabs .flex-tabs .tab {
  flex-grow: 1;
  max-height: 40px;
}

.worko-tabs .flex-tabs .panel {
  background-color: #fff;
  padding: 20px;
  min-height: 300px;
  display: none;
  width: 100%;
  flex-basis: auto;
}

.worko-tabs .tab {
  display: inline-block;
  padding: 10px;
  vertical-align: top;
  background-color: #eee;
  cursor: pointer;
  cursor: hand;
  border-left: 10px solid #ccc;
}

.worko-tabs .tab:hover {
  background-color: #fff;
}

#tab-one:checked ~ .tabs #tab-one-label,
#tab-two:checked ~ .tabs #tab-two-label,
#tab-three:checked ~ .tabs #tab-three-label,
#tab-four:checked ~ .tabs #tab-four-label,
#tab-five:checked ~ .tabs #tab-five-label {
  background-color: #fff;
  cursor: default;
  color: #ff3131;
  border-left-color: #ff3131;
}

#tab-one:checked ~ .tabs #tab-one-panel,
#tab-two:checked ~ .tabs #tab-two-panel,
#tab-three:checked ~ .tabs #tab-three-panel,
#tab-four:checked ~ .tabs #tab-four-panel,
#tab-five:checked ~ .tabs #tab-five-panel {
  display: block;
}

@media (max-width: 600px) {
  .worko-tabs .flex-tabs {
    flex-direction: column;
  }

  .worko-tabs .flex-tabs .tab {
    background: #fff;
    border-bottom: 1px solid #ccc;
  }

  .worko-tabs .flex-tabs .tab:last-of-type {
    border-bottom: none;
  }

  #tab-one-label {
    order: 1;
  }
  #tab-two-label {
    order: 3;
  }
  #tab-three-label {
    order: 5;
  }
  #tab-four-label {
    order: 7;
  }
  #tab-five-label {
    order: 9;
  }
  #tab-one-panel {
    order: 2;
  }
  #tab-two-panel {
    order: 4;
  }
  #tab-three-panel {
    order: 6;
  }
  #tab-four-panel {
    order: 8;
  }
  #tab-five-panel {
    order: 10;
  }

  #tab-one:checked ~ .tabs #tab-one-label,
  #tab-two:checked ~ .tabs #tab-two-label,
  #tab-three:checked ~ .tabs #tab-three-label,
  #tab-four:checked ~ .tabs #tab-four-label,
  #tab-five:checked ~ .tabs #tab-five-label {
    border-bottom: none;
  }

  #tab-one:checked ~ .tabs #tab-one-panel,
  #tab-two:checked ~ .tabs #tab-two-panel,
  #tab-three:checked ~ .tabs #tab-three-panel,
  #tab-four:checked ~ .tabs #tab-four-panel,
  #tab-five:checked ~ .tabs #tab-five-label {
    border-bottom: 1px solid #ccc;
  }
  
  
}

.services_tabs h5 {
  margin: 20px 0px;
}
/* .services_tabs h3 {
  margin: 10px 0px;
} */

.services_tabs p {
  margin: 25px 0px;
}

.home_banner_img1 {
}
.home_banner_form {
  position: relative;
  /* bottom: 60px; */
  background-color: #ff1f27;
  padding: 20px;
  border-radius: 20px;
}
.home_banner_form h5 {
  color: #fff;
  text-align: center;
}
.why_chooseUs_container
{
  margin-top:2vh;
}
.why_chooseUs_container h6
{
 font-weight: 600;

}

.why_chooseUs_container p{
  font-size: 14px;
  font-weight: 500;
}
.services_cards
{
  height:70vh;
  border-radius:20px;
  padding:20px;
 
}
.services_cards button{
  
    width:fit-content;
    padding:10px;
    border: none;
    background-color: #ff1111;
    color:white;
    border-radius: 10px;
}
.services_cards p{
  font-size:14px;
  text-align: center;

}
@media (max-width: 1000px) {
  .services_cards {
   height: auto;
  }
}
.Count_Container
{
  height: 40vh;
  display: flex;
  align-items: center;
 
background-image: linear-gradient(307deg, rgba(255, 0, 0, 0.733) 20%, rgba(245, 241, 241, 0.6) 15%);
opacity: 1;
transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

}
.Count_Container span
{
  font-weight: 800;
}
.Count_Container h6
{
  color: red;
}
@media (max-width: 767px) {
  .Count_Container{
    height:auto
  }
  .Count_Container span
{
  font-weight: 600;
}

}

